import React, { useState } from 'react'
import style from './Presentacion.module.css'
import { BsArrowDown } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Presentacion = () => {

    const [hoverFlag, setHoverFlag] = useState(false)
    const [botonHover, setBotonHover] = useState(false)
    const [flagScroll,setFlagScroll] = useState(false)

    if(!flagScroll){
        setFlagScroll(true)
        window.scrollTo(0,0)
    }

    const [t] = useTranslation();

    return (
        <div className={style.presentacion}>
            <div className={style.divText}>
                <p className={style.text}>{t("HomePage.Presentation.digital-solutions")}</p>
                <div className={style.divText2}>
                    <p className={style.text}>{t("HomePage.Presentation.for-your")}</p>
                    <p className={style.empresa}>{t("HomePage.Presentation.company")}.</p>
                </div>
            </div>
            <Link className={style.link} to='/productos'>
                <div className={hoverFlag ? style.verProductosB : style.verProductosA} onMouseEnter={() => setHoverFlag(true)}>
                    {t("HomePage.Presentation.see-our-products")}
                </div>
            </Link>
            <div className={style.productosDestacados}>
                <p className={style.textDestacados}>{t("HomePage.Presentation.featured-products")}</p>
                <div className={style.botonContainer} onMouseEnter={() => setBotonHover(true)} onMouseLeave={() => setBotonHover(false)} onClick={() => { window.scrollTo(0, window.innerHeight * 0.9) }}>
                    <div className={botonHover ? style.botonDestacadosTrue : style.botonDestacadosFalse}>
                        <BsArrowDown className={style.arrow} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentacion