import React from 'react'
import style from './Curso.module.css'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Presentacion from './comps/Presentacion'
import Detalles from './comps/Detalles'
import Consulta from './comps/Consulta'
import MasCursos from './comps/MasCursos'

import infoCurso from './infoCurso'
import infoCardCursos from './infoCardCursos'

const Curso = () => {
  const [t] = useTranslation();
  const { id } = useParams()

  const info = infoCurso(id, t)
  const arrayCursos = infoCardCursos(t)

  return (
    <div className={style.curso}>
      {
        info ?
          <>
            <Presentacion info={info} />
            <Detalles info={info} t={t} />
            <Consulta info={info} t={t} />
            <MasCursos info={info} arrayCursos={arrayCursos} t={t} />
          </> :
          <div></div>
      }
    </div>
  )
}

export default Curso