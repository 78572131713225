import React from 'react'
import style from './TerceraParte.module.css'
import { useTranslation } from 'react-i18next'

import imagen1 from '../../../utils/images/IT-outsourcing.jpg'
import imagen2 from '../../../utils/images/Soluciones-tecnologicas.jpg'
import imagen3 from '../../../utils/images/testing-validacion.jpg'

const TerceraParte = () => {
  const [t] = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.divContainer}>
        <img className={style.imagen} src={imagen1} alt="" />
        <div className={style.divText}>
          <p className={style.title}>{t("Us.ThirdPart.IT-outsourcing-services")}</p>
          <p className={style.text}>{t("Us.ThirdPart.IT-outsourcing-services-p1")}</p>
          <p className={style.text}>{t("Us.ThirdPart.IT-outsourcing-services-p2")}</p>
        </div>
      </div>
      <div className={style.divContainerA}>
        <div className={style.divText2}>
          <p className={style.title}>{t("Us.ThirdPart.technological-solutions")}</p>
          <p className={style.text}>{t("Us.ThirdPart.technological-solutions-p1")}</p>
          <p className={style.text}>{t("Us.ThirdPart.technological-solutions-p2")}</p>
        </div>
        <img className={style.imagen} src={imagen2} alt="" />
      </div>
      <div className={style.divContainer}>
        <img className={style.imagen} src={imagen3} alt="" />
        <div className={style.divText}>
          <p className={style.title}>{t("Us.ThirdPart.testing-and-validation")}</p>
          <p className={style.text}>{t("Us.ThirdPart.testing-and-validation-p1")}</p>
          <p className={style.text}>{t("Us.ThirdPart.testing-and-validation-p2")}</p>
        </div>
      </div>
    </div>
  )
}

export default TerceraParte