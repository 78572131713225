import React, { useState } from 'react'
import style from './Contactanos.module.css'
import { useTranslation } from 'react-i18next';

const Contactanos = () => {
    
    const [flagScroll,setFlagScroll] = useState(false)

    if(!flagScroll){
        setFlagScroll(true)
        window.scrollTo(0,0)
    }

    const [t] = useTranslation();

    return (
        <div className={style.container}>
            <p className={style.title}>{t("Contact.Contact-us.contact-us")}</p>
            <p className={style.text}>{t("Contact.Contact-us.paragraph1")}<br />{t("Contact.Contact-us.paragraph2")}</p>
        </div>
    )
}

export default Contactanos