import React from 'react'
import style from './Productos.module.css'
import Presentacion from './comps/Presentacion'
import ProductosComp from './comps/ProductosComp'

const Productos = () => {
  return (
    <div className={style.productos}>
      <Presentacion/>
      <ProductosComp/>
    </div>
  )
}

export default Productos