import React from 'react'
import style from './PrimeraParte.module.css'
import { useTranslation } from 'react-i18next'

import imagen1 from '../../../utils/images/ingenieria-producto.jpg'
import imagen2 from '../../../utils/images/Gestion-deInformacion-y-Procesos-del-Producto.jpg'
import imagen3 from '../../../utils/images/e-learning-photo.jpg'
import { Link } from 'react-router-dom'

const PrimeraParte = () => {
  const [t] = useTranslation();
  return (
    <div className={style.container}>
      <div className={style.divContainer}>
        <img className={style.imagen} src={imagen1} alt="" />
        <div className={style.divText}>
          <p className={style.title}>{t("Services.FirstPart.title1")}</p>
          <p className={style.text}>{t("Services.FirstPart.text1")}</p>
        </div>
      </div>

      <div className={style.divContainer2}>
        <div className={style.divText2}>
          <p className={style.title2}>{t("Services.FirstPart.title2")}</p>
          <p className={style.text2}>{t("Services.FirstPart.text2A")}<br/>{t("Services.FirstPart.text2B")}</p>
        </div>
        <img className={style.imagen} src={imagen2} alt="" />
      </div>

      <div className={style.divContainer}>
        <img className={style.imagen} src={imagen3} alt="" />
        <div className={style.divText}>
          <p className={style.title}>{t("Services.FirstPart.title3")}</p>
          <p className={style.text}>{t("Services.FirstPart.text3")}</p>
          <Link className={style.link} to='/cursos'>
            <div className={style.verProductos}>{t("Services.FirstPart.course-catalog")}</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PrimeraParte