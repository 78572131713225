import React from 'react'
import style from './PageInicio.module.css'
import Presentacion from './presentacion/Presentacion'
import Productos from './productos/Productos'
import Carrusel from '../carrusel/Carrusel'
import Elearning from './elearning/Elearning'
import { useTranslation } from 'react-i18next'
import ServiciosComp from './servicios/Servicios'

const PageInicio = () => {
  const [t] = useTranslation();

  return (
    <div className={style.inicio}>
      <Presentacion />
      <Productos />
      <Carrusel />
      <Elearning />
      <ServiciosComp/>
    </div>
  )
}

export default PageInicio