import React, { useState } from 'react'
import style from './PrimerParte.module.css'
import { useTranslation } from 'react-i18next'
import logo from '../../../utils/images/isotipo-ingertec.png'
import imagen1 from '../../../utils/images/aicon1.png'
import imagen2 from '../../../utils/images/aicon2.png'
import imagen3 from '../../../utils/images/aicon3.png'
import imagen4 from '../../../utils/images/aicon4.png'

const PrimerParte = () => {
    
    const [flagScroll,setFlagScroll] = useState(false)

    if(!flagScroll){
        setFlagScroll(true)
        window.scrollTo(0,0)
    }

    const [t] = useTranslation();

    return (
        <div className={style.container}>
            <div className={style.fondoA}></div>
            <div className={style.fondoB}></div>
            <div className={style.areaImagenes}>
                <img className={style.logo} src={logo} alt="" />
                <div className={style.imagen1}>
                    <img className={style.imagen} src={imagen1} alt="" />
                </div>
                <div className={style.imagen2}>
                    <img className={style.imagen} src={imagen2} alt="" />
                </div>
                <div className={style.imagen3}>
                    <img className={style.imagen} src={imagen3} alt="" />
                </div>
                <div className={style.imagen4}>
                    <img className={style.imagen} src={imagen4} alt="" />
                </div>
            </div>
            <div className={style.areaTexto}>
                <p className={style.sobre}>{t("Us.FirstPart.about-ingertec")}</p>
                <div className={style.carrusel}>
                    <div className={style.divText}>
                        <p className={style.text1}>{t("Us.FirstPart.paragraph1")}</p>
                        <p className={style.text2}>{t("Us.FirstPart.paragraph2")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrimerParte