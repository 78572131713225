import React from 'react'
import style from './MasProductos.module.css'

import imagen5 from '../../../../utils/images/box-5.png'
import imagen3 from '../../../../utils/images/box-3.png'
import CardProductos from '../../../generales/CardProductos'

const MasProductos = ({ t }) => {

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.Geontracker.More-products.title')}</p>
      <div className={style.productos}>
        <CardProductos
          imagen={imagen5}
          link={"/productos/interactive-voice-response"}
          title={"HomePage.Products.interactive-voice-response"}
          text={"HomePage.Products.description-of-interactive-voice-response"}
        />
        <CardProductos
            imagen={imagen3}
            link={"/productos/factory-scada"}
            title={"HomePage.Products.factory-scada"}
            text={"HomePage.Products.description-of-factory-scada"}
        />
      </div>
    </div>
  )
}

export default MasProductos