import React, { useState } from 'react'
import style from './Presentacion.module.css'
import { useTranslation } from 'react-i18next'

const Presentacion = () => {
  
  const [flagScroll, setFlagScroll] = useState(false)

  if (!flagScroll) {
    setFlagScroll(true)
    window.scrollTo(0,0)
  }

  const [t] = useTranslation();

  return (
    <div className={style.container}>
      <p className={style.title}>{t("Customers.title")}</p>
      <p className={style.text}>{t("Customers.text1")} <br /> {t("Customers.text2")}</p>
    </div>
  )
}

export default Presentacion