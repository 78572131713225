import React from 'react'
import style from './SistemaDeIntervencionYControl.module.css'
import { useTranslation } from 'react-i18next'
import Presentacion from './comps/Presentacion'
import PrimeraParte from './comps/PrimeraParte'
import Solicitar from './comps/Solicitar'
import Carrusel from '../comun/Carrusel'
import MasProductos from './comps/MasProductos'

const SistemaDeIntervencionYControl = () => {
  const [t] = useTranslation();
  return (
    <div className={style.container}>
      <Presentacion t={t} />
      <PrimeraParte t={t} />
      <Solicitar t={t} />
      <Carrusel t={t} />
      <MasProductos t={t} />
    </div>
  )
}

export default SistemaDeIntervencionYControl