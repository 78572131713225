import React, { useState } from 'react'
import style from './SegundaParte.module.css'
import { useTranslation } from 'react-i18next'

const SegundaParte = () => {
    const [t] = useTranslation();
    const [estado, setEstado] = useState(1)

    return (
        <div className={style.container}>
            <div className={style.divIzq}>
                <div className={estado === 1 ? style.optionHover : style.option} onMouseEnter={() => setEstado(1)}>
                    {t("Services.SecondPart.Training.title")}
                </div>
                <div className={estado === 2 ? style.optionHover : style.option} onMouseEnter={() => setEstado(2)}>
                    {t("Services.SecondPart.Automation-and-SCADA.title")}
                </div>
                <div className={estado === 3 ? style.optionHover : style.option} onMouseEnter={() => setEstado(3)}>
                    {t("Services.SecondPart.IVR-voice-system.title")}
                </div>
                <div className={estado === 4 ? style.optionHover : style.option} onMouseEnter={() => setEstado(4)}>
                    {t("Services.SecondPart.Turnkey-service.title")}
                </div>
            </div>
            <div className={style.divDer}>
                <div className={estado === 1 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Services.SecondPart.Training.title")}</p>
                    <p className={style.text}>{t("Services.SecondPart.Training.text")}</p>
                </div>
                <div className={estado === 2 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Services.SecondPart.Automation-and-SCADA.title")}</p>
                    <p className={style.text}>{t("Services.SecondPart.Automation-and-SCADA.text")}</p>
                    <p className={style.text}>{t("Services.SecondPart.Automation-and-SCADA.text2")}</p>
                </div>
                <div className={estado === 3 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Services.SecondPart.IVR-voice-system.title")}<br />({t("Services.SecondPart.IVR-voice-system.title2")})</p>
                    <p className={style.text}>{t("Services.SecondPart.IVR-voice-system.text")}</p>
                    <p className={style.text}>{t("Services.SecondPart.IVR-voice-system.text2")}</p>
                </div>
                <div className={estado === 4 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Services.SecondPart.Turnkey-service.title")}</p>
                    <p className={style.text}>{t("Services.SecondPart.Turnkey-service.text")}<br />{t("Services.SecondPart.Turnkey-service.text2")}</p>
                </div>
            </div>
        </div>
    )
}

export default SegundaParte