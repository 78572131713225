import React, { useState } from 'react'
import style from './SegundaParte.module.css'
import { useTranslation } from 'react-i18next'

const SegundaParte = () => {
    const [t] = useTranslation();
    const [estado, setEstado] = useState(1)

    return (
        <div className={style.container}>
            <div className={style.divIzq}>
                <div className={estado === 1 ? style.optionHover : style.option} onMouseEnter={() => setEstado(1)}>
                    {t("Us.SecondPart.target-market")}
                </div>
                <div className={estado === 2 ? style.optionHover : style.option} onMouseEnter={() => setEstado(2)}>
                    {t("Us.SecondPart.excellence")}
                </div>
                <div className={estado === 3 ? style.optionHover : style.option} onMouseEnter={() => setEstado(3)}>
                    {t("Us.SecondPart.the-future")}
                </div>
            </div>
            <div className={style.divDer}>
                <div className={estado === 1 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Us.SecondPart.target-market")}</p>
                    <p className={style.text}>{t("Us.SecondPart.target-market-p1")}</p>
                    <p className={style.text}>{t("Us.SecondPart.target-market-p2")}</p>
                </div>
                <div className={estado === 2 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Us.SecondPart.excellence-p1")}</p>
                    <p className={style.text}>{t("Us.SecondPart.excellence-p2")}</p>
                </div>
                <div className={estado === 3 ? style.divText : style.divNone}>
                    <p className={style.title}>{t("Us.SecondPart.the-future-p1")}</p>
                    <p className={style.text}>{t("Us.SecondPart.the-future-p2")}</p>
                </div>
            </div>
        </div>
    )
}

export default SegundaParte