import imagen1 from "../../../../utils/images/activos-icon.png";
import imagen2 from "../../../../utils/images/cobranzas-icon.png";
import imagen3 from "../../../../utils/images/ventas-icon.png";
import imagen4 from "../../../../utils/images/contable-icon.png";
import imagen5 from "../../../../utils/images/empleados-icon.png";
import imagen6 from "../../../../utils/images/mantenimiento-icon.png";
import imagen7 from "../../../../utils/images/inventario-icon.png";
import imagen8 from "../../../../utils/images/produccion-icon.png";
import imagen9 from "../../../../utils/images/facturacion-icon.png";
import imagen10 from "../../../../utils/images/pagos-icon.png";
import imagen11 from "../../../../utils/images/compras-icon.png";

const SegundaParteInfoCards = (t) => {
  const array = [
    {
      imagen: imagen1,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text"),
    },
    {
      imagen: imagen2,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title2"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text2"),
    },
    {
      imagen: imagen3,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title3"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text3"),
    },
    {
      imagen: imagen4,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title4"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text4"),
    },
    {
      imagen: imagen5,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title5"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text5"),
    },
    {
      imagen: imagen6,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title6"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text6"),
    },
    {
      imagen: imagen7,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title7"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text7"),
    },
    {
      imagen: imagen8,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title8"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text8"),
    },
    {
      imagen: imagen9,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title9"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text9"),
    },
    {
      imagen: imagen10,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title10"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text10"),
    },
    {
      imagen: imagen11,
      titulo: t("Products.BusinessSolution.SecondPart.InforCards.title11"),
      text: t("Products.BusinessSolution.SecondPart.InforCards.text11"),
    },
  ];

  return array;
};

export default SegundaParteInfoCards;
