import React from 'react'
import style from './Nosotros.module.css'
import PrimerParte from './nosotros/PrimerParte'
import Carrusel from '../carrusel/Carrusel'
import Servicios from '../inicio/servicios/Servicios'
import SegundaParte from './nosotros/SegundaParte'
import TerceraParte from './nosotros/TerceraParte'

const Nosotros = () => {
  return (
    <div className={style.nosotros}>
      <PrimerParte/>
      <SegundaParte/>
      <TerceraParte/>
      <Carrusel/>
      <Servicios/>
    </div>
  )
}

export default Nosotros