import React, { useState } from 'react'
import style from './Elearning.module.css'

import { useTranslation } from 'react-i18next'

import imagen1 from '../../../utils/images/AdministracionGestion.jpg'
import imagen2 from '../../../utils/images/CursoIdiomas.jpg'
import imagen3 from '../../../utils/images/CursoDisenio.jpg'
import imagen4 from '../../../utils/images/EstrategiaEmpresarial.jpg'
import { Link } from 'react-router-dom'

const Elearning = () => {
    const [t] = useTranslation();

    const [card1, setCard1] = useState(false)
    const [card2, setCard2] = useState(false)
    const [card3, setCard3] = useState(false)
    const [card4, setCard4] = useState(false)

    return (
        <div className={style.elearning}>
            <p className={style.text1}>{t("HomePage.Elearning.elearning-courses")}</p>
            <p className={style.text2}>{t("HomePage.Elearning.subtitle")}</p>
            <div className={style.cardsContainer}>
                <Link className={style.link} to='/cursos/1'>
                    <div className={style.cardContainer} onMouseEnter={() => setCard1(true)} onMouseLeave={() => setCard1(false)}>
                        <div className={card1 ? style.cardTrue : style.cardFalse}>
                            <img src={imagen1} className={card1 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                            <p className={card1 ? style.textCard1True : style.textCard1False}>{t("HomePage.Elearning.administration-and-management")}</p>
                            <p className={card1 ? style.textCard2True : style.textCard2False}>135 {t("HomePage.Elearning.hours")}</p>
                            <p className={card1 ? style.verCursoTrue : style.verCursoFalse}>{t("HomePage.Elearning.see-course")}</p>
                        </div>
                    </div>
                </Link>
                <Link className={style.link} to='/cursos/8'>
                    <div className={style.cardContainer} onMouseEnter={() => setCard2(true)} onMouseLeave={() => setCard2(false)}>
                        <div className={card2 ? style.cardTrue : style.cardFalse}>
                            <img src={imagen2} className={card2 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                            <p className={card2 ? style.textCard1True : style.textCard1False}>{t("HomePage.Elearning.language-course")}</p>
                            <p className={card2 ? style.textCard2True : style.textCard2False}>840 {t("HomePage.Elearning.hours")}</p>
                            <p className={card2 ? style.verCursoTrue : style.verCursoFalse}>{t("HomePage.Elearning.see-course")}</p>
                        </div>
                    </div>
                </Link>
                <Link className={style.link} to='/cursos/9'>
                    <div className={style.cardContainer} onMouseEnter={() => setCard3(true)} onMouseLeave={() => setCard3(false)}>
                        <div className={card3 ? style.cardTrue : style.cardFalse}>
                            <img src={imagen3} className={card3 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                            <p className={card3 ? style.textCard1True : style.textCard1False}>{t("HomePage.Elearning.design-course")}</p>
                            <p className={card3 ? style.textCard2True : style.textCard2False}>325 {t("HomePage.Elearning.hours")}</p>
                            <p className={card3 ? style.verCursoTrue : style.verCursoFalse}>{t("HomePage.Elearning.see-course")}</p>
                        </div>
                    </div>
                </Link>
                <Link className={style.link} to='/cursos/5'>
                    <div className={style.cardContainer} onMouseEnter={() => setCard4(true)} onMouseLeave={() => setCard4(false)}>
                        <div className={card4 ? style.cardTrue : style.cardFalse}>
                            <img src={imagen4} className={card4 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                            <p className={card4 ? style.textCard1True : style.textCard1False}>{t("HomePage.Elearning.business-strategy-and-management")}</p>
                            <p className={card4 ? style.textCard2True : style.textCard2False}>189 {t("HomePage.Elearning.hours")}</p>
                            <p className={card4 ? style.verCursoTrue : style.verCursoFalse}>{t("HomePage.Elearning.see-course")}</p>
                        </div>
                    </div>
                </Link>
            </div>

            <Link className={style.link} to='/cursos'>
                <div className={style.verProductos}>{t("HomePage.Products.see-more-courses")}</div>
            </Link>
        </div>
    )
}

export default Elearning