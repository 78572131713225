import React, { useState } from 'react'
import style from './Presentacion.module.css'

const Presentacion = ({ info }) => {
    
    const [flagScroll,setFlagScroll] = useState(false)

    if(!flagScroll){
        setFlagScroll(true)
        window.scrollTo(0,0)
    }

    const [hover, setHover] = useState(false)

    return (
        <div className={style.container}>
            <div className={style.divIzq}>
                <div className={style.circulosContenedor} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={hover ? style.circulo2Hover : style.circulo2}>
                        <div className={hover ? style.circulo1Hover : style.circulo1}>
                            <img className={hover ? style.imagenHover : style.imagen} src={info.imagen} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.divDer}>
                <p className={style.titulo}>{info.titulo}</p>
                <p className={style.text1}>{info.text1}</p>
                <p className={style.text2}>{info.text2}</p>
            </div>
        </div>
    )
}

export default Presentacion