import React, { useState } from 'react'
import style from './FormYMapa.module.css'
import { useTranslation } from 'react-i18next'
import axios from 'axios';

const FormYMapa = () => {
  const [t] = useTranslation();

  const [enviado, setEnviado] = useState(false)
  const [error, setError] = useState(false)
  const [nombre, setNombre] = useState('')
  const [telefono, setTelefono] = useState('')
  const [correo, setCorreo] = useState('')
  const [mensaje, setMensaje] = useState('')

  async function sendEmail() {

    if (nombre.length > 0 && telefono.length > 0 && correo.length > 0 && mensaje.length > 0) {
      document.getElementById('estado').innerText = t("Contact.FormAndMap.sending")
      setEnviado(true)

      let body = {
        "nombreYApellido": nombre,
        "telefono": telefono,
        "correoElectronico": correo,
        "mensajeDescripcion": mensaje
      }

      try {
        await axios.post('https://contacto.ingertec.com.ar/api/Contacto', body)

        /* document.getElementById('nomb').value = ''
        document.getElementById('num').value = ''
        document.getElementById('mail').value = ''
        document.getElementById('msj').value = ''
        setNombre('')
        setTelefono('')
        setCorreo('')
        setMensaje('') */

        document.getElementById('estado').innerText = t("Contact.FormAndMap.message-sent")
      } catch (error) {
        document.getElementById('estado').innerText = t("Contact.FormAndMap.message-error")
      }
    } else {
      setError(true)
    }
  }

  return (
    <div className={style.container}>

      <div className={style.form}>
        <div>
          <p className={style.text}>{t("Contact.FormAndMap.title")}</p>
          <div className={style.separador}></div>
        </div>
        <input className={style.input} type="text" id='nomb' onChange={e => { setNombre(e.target.value); setError(false) }} placeholder={`${t("Contact.FormAndMap.name-surname")}*`} />
        <input className={style.input} type="text" id='num' onChange={e => { setTelefono(e.target.value); setError(false) }} placeholder={`${t("Contact.FormAndMap.phone")}`} />
        <input className={style.input} type="email" id='mail' onChange={e => { setCorreo(e.target.value); setError(false) }} placeholder={`${t("Contact.FormAndMap.email")}`} />
        <textarea className={style.mensaje} name="mensaje" id='msj' onChange={e => { setMensaje(e.target.value); setError(false) }} placeholder={`${t("Contact.FormAndMap.message")}*`} />
        <div style={{ width: '100%' }}>
          <div className={style.enviar} onClick={() => sendEmail()}>{t("Contact.FormAndMap.send-message")}</div>
          {
            error ?
              <p className={style.enviandoTrue} id='error'>Por favor, completar todos los campos.</p> :
              <p className={enviado ? style.enviandoTrue : style.enviandoFalse} id='estado'>a</p>
          }
        </div>
      </div>

      <div className={style.map}>
        {
          window.location.origin === "https://www.ingertec.com.br" ?
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d918.8745804996071!2d-43.1210276!3d-22.8949836!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x998369a806aa35%3A0xee17ba1bc31000d2!2sR.%20Eduardo%20Luiz%20Gomes%2C%2013%20-%20Centro%2C%20Niter%C3%B3i%20-%20RJ%2C%2024020-340%2C%20Brasil!5e0!3m2!1ses-419!2sar!4v1697121697757!5m2!1ses-419!2sar" className={style.mapa} loading="lazy"/*  referrerpolicy="no-referrer-when-downgrade" */></iframe> :
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3282.8416580649214!2d-58.4016235!3d-34.6334415!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccb174dad1439%3A0x371d8ff8ffedf9bd!2sAv.%20Jujuy%201963%2C%20C1247ABH%20CABA!5e0!3m2!1ses!2sar!4v1682689338155!5m2!1ses!2sar" className={style.mapa} loading="lazy"></iframe>
        }
      </div>
    </div>
  )
}

export default FormYMapa