import React, { useState } from 'react'
import style from './MasCursos.module.css'
import { Link } from 'react-router-dom'

import imagen from '../../../utils/images/CursoIdiomas.jpg'

const MasCursos = ({ info, arrayCursos, t }) => {

  const [card1, setCard1] = useState(false)
  const [card2, setCard2] = useState(false)
  const [card3, setCard3] = useState(false)
  const [card4, setCard4] = useState(false)

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t("E-learning.Elearning.title")}</p>
      <div className={style.cursos}>
        <Link className={style.link} to='/cursos'>
          <div className={style.cardContainer} onMouseEnter={() => setCard1(true)} onMouseLeave={() => setCard1(false)}>
            <div className={card1 ? style.cardTrue : style.cardFalse}>
              <img src={arrayCursos[info.masCursos[0] - 1].imagen} className={card1 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
              <p className={card1 ? style.textCard1True : style.textCard1False}>{arrayCursos[info.masCursos[0] - 1].nombre}</p>
              <p className={card1 ? style.textCard2True : style.textCard2False}>{arrayCursos[info.masCursos[0] - 1].horas} {t("E-learning.Elearning.hours")}</p>
              <p className={card1 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
            </div>
          </div>
        </Link>
        <Link className={style.link} to='/cursos'>
          <div className={style.cardContainer} onMouseEnter={() => setCard2(true)} onMouseLeave={() => setCard2(false)}>
            <div className={card2 ? style.cardTrue : style.cardFalse}>
              <img src={arrayCursos[info.masCursos[1] - 1].imagen} className={card2 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
              <p className={card2 ? style.textCard1True : style.textCard1False}>{arrayCursos[info.masCursos[1] - 1].nombre}</p>
              <p className={card2 ? style.textCard2True : style.textCard2False}>{arrayCursos[info.masCursos[1] - 1].horas} {t("E-learning.Elearning.hours")}</p>
              <p className={card2 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
            </div>
          </div>
        </Link>
        <Link className={style.link} to='/cursos'>
          <div className={style.cardContainer} onMouseEnter={() => setCard3(true)} onMouseLeave={() => setCard3(false)}>
            <div className={card3 ? style.cardTrue : style.cardFalse}>
              <img src={arrayCursos[info.masCursos[2] - 1].imagen} className={card3 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
              <p className={card3 ? style.textCard1True : style.textCard1False}>{arrayCursos[info.masCursos[2] - 1].nombre}</p>
              <p className={card3 ? style.textCard2True : style.textCard2False}>{arrayCursos[info.masCursos[2] - 1].horas} {t("E-learning.Elearning.hours")}</p>
              <p className={card3 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
            </div>
          </div>
        </Link>
        <Link className={style.link} to='/cursos'>
          <div className={style.cardContainer} onMouseEnter={() => setCard4(true)} onMouseLeave={() => setCard4(false)}>
            <div className={card4 ? style.cardTrue : style.cardFalse}>
              <img src={arrayCursos[info.masCursos[3] - 1].imagen} className={card4 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
              <p className={card4 ? style.textCard1True : style.textCard1False}>{arrayCursos[info.masCursos[3] - 1].nombre}</p>
              <p className={card4 ? style.textCard2True : style.textCard2False}>{arrayCursos[info.masCursos[3] - 1].horas} {t("E-learning.Elearning.hours")}</p>
              <p className={card4 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default MasCursos