import React, { useState } from 'react'
import style from './Elearning.module.css'
import { useTranslation } from 'react-i18next'

import imagen1 from '../../../utils/images/AdministracionGestion.jpg'
import imagen2 from '../../../utils/images/AtencionCliente.jpg'
import imagen3 from '../../../utils/images/CursoComercio.jpg'
import imagen4 from '../../../utils/images/ComercialVentas.jpg'
import imagen5 from '../../../utils/images/EstrategiaEmpresarial.jpg'
import imagen6 from '../../../utils/images/MarketingInternet.jpg'
import imagen7 from '../../../utils/images/RecursosHumanos.jpg'
import imagen8 from '../../../utils/images/CursoIdiomas.jpg'
import imagen9 from '../../../utils/images/CursoDisenio.jpg'
import imagen10 from '../../../utils/images/HabilidadesDirectivas.jpg'
import imagen11 from '../../../utils/images/Programacion.jpg'
import { Link } from 'react-router-dom'

const Elearning = () => {
    const [t] = useTranslation();
    const [card1, setCard1] = useState(false)
    const [card2, setCard2] = useState(false)
    const [card3, setCard3] = useState(false)
    const [card4, setCard4] = useState(false)
    const [card5, setCard5] = useState(false)
    const [card6, setCard6] = useState(false)
    const [card7, setCard7] = useState(false)
    const [card8, setCard8] = useState(false)
    const [card9, setCard9] = useState(false)
    const [card10, setCard10] = useState(false)
    const [card11, setCard11] = useState(false)

    return (
        <div className={style.container}>
            <Link className={style.link} to='/cursos/1'>
                <div className={style.cardContainer} onMouseEnter={() => setCard1(true)} onMouseLeave={() => setCard1(false)}>
                    <div className={card1 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen1} className={card1 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card1 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.administration-and-management")}</p>
                        <p className={card1 ? style.textCard2True : style.textCard2False}>135 {t("E-learning.Elearning.hours")}</p>
                        <p className={card1 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/2'>
                <div className={style.cardContainer} onMouseEnter={() => setCard2(true)} onMouseLeave={() => setCard2(false)}>
                    <div className={card2 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen2} className={card2 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card2 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.customer-support")}</p>
                        <p className={card2 ? style.textCard2True : style.textCard2False}>33 {t("E-learning.Elearning.hours")}</p>
                        <p className={card2 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/3'>
                <div className={style.cardContainer} onMouseEnter={() => setCard3(true)} onMouseLeave={() => setCard3(false)}>
                    <div className={card3 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen3} className={card3 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card3 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.trade-course")}</p>
                        <p className={card3 ? style.textCard2True : style.textCard2False}>145 {t("E-learning.Elearning.hours")}</p>
                        <p className={card3 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/4'>
                <div className={style.cardContainer} onMouseEnter={() => setCard4(true)} onMouseLeave={() => setCard4(false)}>
                    <div className={card4 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen4} className={card4 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card4 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.trade-and-sales")}</p>
                        <p className={card4 ? style.textCard2True : style.textCard2False}>138 {t("E-learning.Elearning.hours")}</p>
                        <p className={card4 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/5'>
                <div className={style.cardContainer} onMouseEnter={() => setCard5(true)} onMouseLeave={() => setCard5(false)}>
                    <div className={card5 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen5} className={card5 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card5 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.business-strategy-and-management")}</p>
                        <p className={card5 ? style.textCard2True : style.textCard2False}>189 {t("E-learning.Elearning.hours")}</p>
                        <p className={card5 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/6'>
                <div className={style.cardContainer} onMouseEnter={() => setCard6(true)} onMouseLeave={() => setCard6(false)}>
                    <div className={card6 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen6} className={card6 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card6 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.marketing-and-internet")}</p>
                        <p className={card6 ? style.textCard2True : style.textCard2False}>369 {t("E-learning.Elearning.hours")}</p>
                        <p className={card6 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/7'>
                <div className={style.cardContainer} onMouseEnter={() => setCard7(true)} onMouseLeave={() => setCard7(false)}>
                    <div className={card7 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen7} className={card7 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card7 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.human-resources")}</p>
                        <p className={card7 ? style.textCard2True : style.textCard2False}>199 {t("E-learning.Elearning.hours")}</p>
                        <p className={card7 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/8'>
                <div className={style.cardContainer} onMouseEnter={() => setCard8(true)} onMouseLeave={() => setCard8(false)}>
                    <div className={card8 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen8} className={card8 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card8 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.language-courses")}</p>
                        <p className={card8 ? style.textCard2True : style.textCard2False}>840 {t("E-learning.Elearning.hours")}</p>
                        <p className={card8 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/9'>
                <div className={style.cardContainer} onMouseEnter={() => setCard9(true)} onMouseLeave={() => setCard9(false)}>
                    <div className={card9 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen9} className={card9 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card9 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.design-course")}</p>
                        <p className={card9 ? style.textCard2True : style.textCard2False}>325 {t("E-learning.Elearning.hours")}</p>
                        <p className={card9 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/10'>
                <div className={style.cardContainer} onMouseEnter={() => setCard10(true)} onMouseLeave={() => setCard10(false)}>
                    <div className={card10 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen10} className={card10 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card10 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.management-skills")}</p>
                        <p className={card10 ? style.textCard2True : style.textCard2False}>0 {t("E-learning.Elearning.hours")}</p>
                        <p className={card10 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
            <Link className={style.link} to='/cursos/11'>
                <div className={style.cardContainer} onMouseEnter={() => setCard11(true)} onMouseLeave={() => setCard11(false)}>
                    <div className={card11 ? style.cardTrue : style.cardFalse}>
                        <img src={imagen11} className={card11 ? style.cardImagenTrue : style.cardImagenFalse} alt="" />
                        <p className={card11 ? style.textCard1True : style.textCard1False}>{t("E-learning.Elearning.programming-and-database")}</p>
                        <p className={card11 ? style.textCard2True : style.textCard2False}>500 {t("E-learning.Elearning.hours")}</p>
                        <p className={card11 ? style.verCursoTrue : style.verCursoFalse}>{t("E-learning.Elearning.see-course")}</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default Elearning