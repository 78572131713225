import React from 'react'
import style from './Servicios.module.css'
import Presentacion from './comps/Presentacion'
import PrimeraParte from './comps/PrimeraParte'
import SegundaParte from './comps/SegundaParte'
import TerceraParte from './comps/TerceraParte'
import ServiciosComp from './comps/ServiciosComp'

const Servicios = () => {
  return (
    <div className={style.servicios}>
      <Presentacion/>
      <PrimeraParte/>
      <SegundaParte/>
      <TerceraParte/>
      <ServiciosComp/>
    </div>
  )
}

export default Servicios