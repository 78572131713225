import React, { useState } from 'react'
import style from './CardProductos.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CardProductos = ({imagen, link, title, text}) => {
  const [t] = useTranslation();
  const [arrow, setArrow] = useState(false)

  return (
    <Link className={style.link} to={link}>
        <div className={style.card}>
            <img src={imagen} className={style.imagen} alt="" />
            <div className={style.divTextCard} onMouseEnter={() => setArrow(true)} onMouseLeave={() => setArrow(false)}>
                <div className={style.divTextArrow}>
                    <p className={style.textCard1}>{t(title)}</p>
                    {/* <AiOutlineArrowRight className={arrow ? style.arrowTrue : style.arrowFalse} /> */}
                </div>
                <p className={style.textCard2}>{t(text)}</p>
            </div>
        </div>
    </Link>
  )
}

export default CardProductos