import React from 'react'
import style from './Footer.module.css'
import { IoIosCall } from 'react-icons/io'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Footer = () => {

    const [t] = useTranslation();

    return (
        <>
            {
                window.location.pathname === '/politicas.html' ?
                    <div className={style.footer}>
                        <div className={style.area2}>
                            <div className={style.divColumnA}>
                                <a href="tel:+541132214318" style={{ display: 'none' }} id='telefono'></a>
                                <div className={style.llamar} onClick={() => document.getElementById('telefono').click()}>
                                    <IoIosCall className={style.icon} />
                                    <p className={style.numero}>(+54 11) 3221-4318</p>
                                </div>
                                <div className={style.direccion}>
                                    <FaMapMarkerAlt className={style.icon} />
                                    <div className={style.divDireccion}>
                                        {
                                            window.location.origin === "https://www.ingertec.com.br" ?
                                                <>
                                                    <p className={style.textDireccion}>Rua Eduardo Luiz Gomes,</p>
                                                    <p className={style.textDireccion}>N° 13, Sala 102, Centro,</p>
                                                    <p className={style.textDireccion}>Niterói.</p>
                                                </> :
                                                <>
                                                    <p className={style.textDireccion}>Av. Jujuy 1963,</p>
                                                    <p className={style.textDireccion}>Piso 9 Dpto A, Capital Federal,</p>
                                                    <p className={style.textDireccion}>Buenos Aires.</p>
                                                </>
                                        }
                                    </div>
                                </div>
                                <a href={window.location.origin === "https://www.ingertec.com.br" ? "mailto:info@ingertec.com.br" : "mailto:info@ingertec.com.ar"} style={{ display: 'none' }} id='email'></a>
                                <div className={style.email} onClick={() => document.getElementById('email').click()}>
                                    <MdOutlineEmail className={style.icon} />
                                    <p className={style.correo}>
                                        {
                                            window.location.origin === "https://www.ingertec.com.br" ?
                                                "info@ingertec.com.br" :
                                                "info@ingertec.com.ar"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className={style.divColumnB}>
                                <p className={style.title}>{t("Navbar.products")}</p>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/productos/business-solution" :'https://ingertec.com.ar/#/productos/business-solution'}>
                                    <p className={style.text}>{t("Footer.business-solution")}</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/productos/factory-scada" :'https://ingertec.com.ar/#/productos/factory-scada'}>
                                    <p className={style.text}>{t("Footer.factory-scada")}</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/productos/interactive-voice-response" :'https://ingertec.com.ar/#/productos/interactive-voice-response'}>
                                    <p className={style.text}>I.V.R. (Interactive Voice Response)</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/productos/sistema-de-intervencion-y-control" :'https://ingertec.com.ar/#/productos/sistema-de-intervencion-y-control'}>
                                    <p className={style.text}>{t("Footer.supervision-and-control-system")}</p>
                                </Link>
                            </div>
                        </div>
                        <div className={style.area2}>
                            <div className={style.divColumnC}>
                                <p className={style.title}>{t("Footer.company")}</p>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/nosotros" :'https://ingertec.com.ar/#/nosotros'}>
                                    <p className={style.text}>{t("Navbar.us")}</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/contacto" :'https://ingertec.com.ar/#/contacto'}>
                                    <p className={style.text}>{t("Navbar.contact")}</p>
                                </Link>
                            </div>
                            <div className={style.divColumnD}>
                                <p className={style.title}>{t("Footer.our-offer")}</p>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/servicios" :'https://ingertec.com.ar/#/servicios'}>
                                    <p className={style.text}>{t("Navbar.services")}</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/productos" :'https://ingertec.com.ar/#/productos'}>
                                    <p className={style.text}>{t("Navbar.products")}</p>
                                </Link>
                                <Link className={style.link} to={window.location.origin === "https://www.ingertec.com.br" ? "https://ingertec.com.br/#/cursos" :'https://ingertec.com.ar/#/cursos'}>
                                    <p className={style.text}>{t("Navbar.elearning")}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={style.footer}>
                        <div className={style.area2}>
                            <div className={style.divColumnA}>
                                <a href="tel:+541132214318" style={{ display: 'none' }} id='telefono'></a>
                                <div className={style.llamar} onClick={() => document.getElementById('telefono').click()}>
                                    <IoIosCall className={style.icon} />
                                    <p className={style.numero}>(+54 11) 3221-4318</p>
                                </div>
                                <div className={style.direccion}>
                                    <FaMapMarkerAlt className={style.icon} />
                                    <div className={style.divDireccion}>
                                        {
                                            window.location.origin === "https://www.ingertec.com.br" ?
                                                <>
                                                    <p className={style.textDireccion}>Rua Eduardo Luiz Gomes,</p>
                                                    <p className={style.textDireccion}>N° 13, Sala 102, Centro,</p>
                                                    <p className={style.textDireccion}>Niterói.</p>
                                                </> :
                                                <>
                                                    <p className={style.textDireccion}>Av. Jujuy 1963,</p>
                                                    <p className={style.textDireccion}>Piso 9 Dpto A, Capital Federal,</p>
                                                    <p className={style.textDireccion}>Buenos Aires.</p>
                                                </>
                                        }
                                    </div>
                                </div>
                                <a href={window.location.origin === "https://www.ingertec.com.br" ? "mailto:info@ingertec.com.br" : "mailto:info@ingertec.com.ar"} style={{ display: 'none' }} id='email'></a>
                                <div className={style.email} onClick={() => document.getElementById('email').click()}>
                                    <MdOutlineEmail className={style.icon} />
                                    <p className={style.correo}>
                                        {
                                            window.location.origin === "https://www.ingertec.com.br" ?
                                                "info@ingertec.com.br" :
                                                "info@ingertec.com.ar"
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className={style.divColumnB}>
                                <p className={style.title}>{t("Navbar.products")}</p>
                                <Link className={style.link} to='/productos/business-solution'>
                                    <p className={style.text}>{t("Footer.business-solution")}</p>
                                </Link>
                                <Link className={style.link} to='/productos/factory-scada'>
                                    <p className={style.text}>{t("Footer.factory-scada")}</p>
                                </Link>
                                <Link className={style.link} to='/productos/interactive-voice-response'>
                                    <p className={style.text}>I.V.R. (Interactive Voice Response)</p>
                                </Link>
                                <Link className={style.link} to='/productos/sistema-de-intervencion-y-control'>
                                    <p className={style.text}>{t("Footer.supervision-and-control-system")}</p>
                                </Link>
                            </div>
                        </div>
                        <div className={style.area2}>
                            <div className={style.divColumnC}>
                                <p className={style.title}>{t("Footer.company")}</p>
                                <Link className={style.link} to='/nosotros'>
                                    <p className={style.text}>{t("Navbar.us")}</p>
                                </Link>
                                <Link className={style.link} to='/contacto'>
                                    <p className={style.text}>{t("Navbar.contact")}</p>
                                </Link>
                            </div>
                            <div className={style.divColumnD}>
                                <p className={style.title}>{t("Footer.our-offer")}</p>
                                <Link className={style.link} to='/servicios'>
                                    <p className={style.text}>{t("Navbar.services")}</p>
                                </Link>
                                <Link className={style.link} to='/productos'>
                                    <p className={style.text}>{t("Navbar.products")}</p>
                                </Link>
                                <Link className={style.link} to='/cursos'>
                                    <p className={style.text}>{t("Navbar.elearning")}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default Footer