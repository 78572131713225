import React from 'react'
import style from './Empresas.module.css'

/* import imagen1 from '../../../utils/images/logojumbocl.png'
import imagen2 from '../../../utils/images/Disco.png'
import imagen3 from '../../../utils/images/vea.png'
import imagen4 from '../../../utils/images/easy.png'
import imagen5 from '../../../utils/images/blaisten.png'
import imagen6 from '../../../utils/images/shopping.png'
import imagen7 from '../../../utils/images/CADIC.png'
import imagen8 from '../../../utils/images/Inmark.jpg'
import imagen9 from '../../../utils/images/Procrear_Bicentenario_Argentina_84d7e_450x450.png'
import imagen10 from '../../../utils/images/progresar.png'
import imagen11 from '../../../utils/images/ConectarIgualdad.png'
import imagen12 from '../../../utils/images/gottert.png'
import imagen13 from '../../../utils/images/dynamic.png'
import imagen14 from '../../../utils/images/bw-group.jpg'
import imagen15 from '../../../utils/images/zaxera.png' */

import imagen1 from '../../../utils/images/logos/1-Jumbo-400x400px.png'
import imagen2 from '../../../utils/images/logos/3-Spid-400x400px.png'
import imagen3 from '../../../utils/images/logos/4-Vea-400x400px.png'
import imagen4 from '../../../utils/images/logos/5-Disco-400x400px.png'
import imagen5 from '../../../utils/images/logos/8-Gbarbosa-400x400px.png'
import imagen6 from '../../../utils/images/logos/9-Mercantil-400x400px.png'
import imagen7 from '../../../utils/images/logos/10-Bretas-400x400px.png'
import imagen8 from '../../../utils/images/logos/10-Perini-400x400px.png'
import imagen9 from '../../../utils/images/logos/11-Prezunic-400x400px.png'
import imagen10 from '../../../utils/images/logos/12-Easy-400x400px.png'
import imagen11 from '../../../utils/images/logos/15-Blaisten-400x400px.png'
import imagen12 from '../../../utils/images/logos/18-ShoppingC-400x400px.png'
import imagen13 from '../../../utils/images/logos/bw-group.jpg'
import imagen14 from '../../../utils/images/logos/ConectarIgualdad.png'
import imagen15 from '../../../utils/images/logos/dynamic.png'
import imagen16 from '../../../utils/images/logos/gottert.png'
import imagen17 from '../../../utils/images/logos/Inmark.jpg'
import imagen18 from '../../../utils/images/logos/Inxpirius.png'
import imagen19 from '../../../utils/images/logos/iupix.png'
import imagen20 from '../../../utils/images/logos/LogoConcreta.png'
import imagen21 from '../../../utils/images/logos/LogoGrafmaq.png'
import imagen22 from '../../../utils/images/logos/LogoPiana.png'
import imagen23 from '../../../utils/images/logos/Procrear_Bicentenario_Argentina_84d7e_450x450.png'
import imagen24 from '../../../utils/images/logos/progresar.png'
import imagen25 from '../../../utils/images/logos/Second Skin.png'
import imagen26 from '../../../utils/images/logos/zaxera.png'
import imagen27 from '../../../utils/images/logos/zetti.png'

const Empresas = () => {
  return (
    <div className={style.container}>
      <a href="https://www.jumbo.com.ar/login/prehome.aspx">
        <img className={style.imagen} src={imagen1} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen2} alt="" />
      </a>
      <a href="https://supermercadosvea.com.ar/">
        <img className={style.imagen} src={imagen3} alt="" />
      </a>
      <a href="https://www.disco.com.ar/">
        <img className={style.imagen} src={imagen4} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen5} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen6} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen7} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen8} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen9} alt="" />
      </a>
      <a href="https://www.easy.com.ar/">
        <img className={style.imagen} src={imagen10} alt="" />
      </a>
      <a href="https://www.blaisten.com.ar/">
        <img className={style.imagen} src={imagen11} alt="" />
      </a>
      <a href="https://www.cencosudshoppingcenters.com/argentina/">
        <img className={style.imagen} src={imagen12} alt="" />
      </a>
      <a href="http://www.bywgroup.com/index.php">
        <img className={style.imagen} src={imagen13} alt="" />
      </a>
      <a href="https://www.argentina.gob.ar/educacion/aprender-conectados/conectar-igualdad">
        <img className={style.imagen} src={imagen14} alt="" />
      </a>
      <a href="https://www.dynamic-solutions.com.ar/">
        <img className={style.imagen} src={imagen15} alt="" />
      </a>
      <a href="https://www.gottert.com.ar/">
        <img className={style.imagen} src={imagen16} alt="" />
      </a>
      <a href="https://www.grupoinmark.com/">
        <img className={style.imagen} src={imagen17} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen18} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen19} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen20} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen21} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen22} alt="" />
      </a>
      <a href="https://www.argentina.gob.ar/interior/procrear">
        <img className={style.imagen} src={imagen23} alt="" />
      </a>
      <a href="https://www.argentina.gob.ar/becasprogresar">
        <img className={style.imagen} src={imagen24} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen25} alt="" />
      </a>
      <a href="http://www.zaxera.com/">
        <img className={style.imagen} src={imagen26} alt="" />
      </a>
      <a /* href="https://www.jumbo.com.ar/login/prehome.aspx" */>
        <img className={style.imagen} src={imagen27} alt="" />
      </a>
    </div>
  )
}

export default Empresas