import React from 'react'
import style from './PrimeraParte.module.css'

const PrimeraParte = ({ t }) => {
  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.SupervisionAndControlSystem.FirstPart.title')}&nbsp;{t('Products.SupervisionAndControlSystem.FirstPart.title2')}</p>
      <div className={style.divContainer}>
        <div className={style.divIzq}>
          <p className={style.text}>{t('Products.SupervisionAndControlSystem.FirstPart.text')}</p>
          <p className={style.text}>{t('Products.SupervisionAndControlSystem.FirstPart.text2')}</p>
          <p className={style.title}>{t('Products.SupervisionAndControlSystem.FirstPart.title3')}</p>
          <div className={style.separador}></div>
          <ul className={style.ul}>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li2')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li3')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li4')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li5')}</li>
          </ul>
        </div>
        <div className={style.divDer}>
          <p className={style.title}>{t('Products.SupervisionAndControlSystem.FirstPart.title4')}</p>
          <div className={style.separador}></div>
          <ul className={style.ul}>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li6')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li7')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li8')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li9')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li10')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li11')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li12')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li13')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li14')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li15')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li16')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li17')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li18')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li19')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li20')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li21')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li22')}</li>
            <li className={style.li}>{t('Products.SupervisionAndControlSystem.FirstPart.li23')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrimeraParte