import React, { useState } from 'react'
import style from './Productos.module.css'
import imagen1 from '../../../utils/images/box-1.png'
import imagen2 from '../../../utils/images/box-2.png'
import imagen4 from '../../../utils/images/box-4.png'
import imagen7 from '../../../utils/images/box-7.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import CardProductos from '../../generales/CardProductos'

const Productos = () => {
    const [t] = useTranslation();

    return (
        <div className={style.productos}>
            <div className={style.divText1}>
                <p className={style.text1}>{t("HomePage.Products.title")}</p>
                <p className={style.text2}>{t("HomePage.Products.subtitle")}</p>
            </div>
            <div className={style.divCards}>
                <div className={style.cards1}>
                    <CardProductos
                        imagen={imagen4}
                        link={"/productos/sistema-de-intervencion-y-control"}
                        title={"HomePage.Products.supervision-and-control-system"}
                        text={"HomePage.Products.description-of-supervision-and-control-system"}
                    />
                    <CardProductos
                        imagen={imagen7}
                        link={"/productos/smartsen"}
                        title={"HomePage.Products.Smartsen"}
                        text={"HomePage.Products.description-of-smartsen"}
                    />
                </div>
                <div className={style.cards2}>
                    <CardProductos
                        imagen={imagen1}
                        link={"/productos/business-solution"}
                        title={"HomePage.Products.business-solution"}
                        text={"HomePage.Products.description-of-business-solution"}
                    />
                    <CardProductos
                        imagen={imagen2}
                        link={"/productos/evo-scada-server"}
                        title={"HomePage.Products.evo-scada-server"}
                        text={"HomePage.Products.description-of-evo-scada-server"}
                    />
                </div>
            </div>
            <div className={style.otrosProductos}>
                <div className={style.divTextProd}>
                    <p className={style.textProd1}>{t("HomePage.Products.not-what-you're-looking-for?")}</p>
                    <p className={style.textProd2}>{t("HomePage.Products.we-can-develop-what-you-need")}</p>
                </div>
                <div className={style.divButtonProd}>
                    <Link className={style.link} to='/productos'>
                        <div className={style.verProductos}>{t("HomePage.Products.see-more-products")}</div>
                    </Link>
                    <Link className={style.link} to='/contacto'>
                        <div className={style.consultas}>{t("HomePage.Products.any-query?")}</div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Productos