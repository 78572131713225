import imagen1 from "../../utils/images/AdministracionGestionC.jpg";
import imagen2 from "../../utils/images/AtencionClienteC.jpg";
import imagen3 from "../../utils/images/CursoComercioC.jpg";
import imagen4 from "../../utils/images/ComercialVentasC.jpg";
import imagen5 from "../../utils/images/EstrategiaEmpresarialC.jpg";
import imagen6 from "../../utils/images/MarketingInternetC.jpg";
import imagen7 from "../../utils/images/RecursosHumanosC.jpg";
import imagen8 from "../../utils/images/CursoIdiomasC.jpg";
import imagen9 from "../../utils/images/CursoDisenioC.jpg";
import imagen10 from "../../utils/images/HabilidadesDirectivasC.jpg";
import imagen11 from "../../utils/images/ProgramacionC.jpg";

const array = (t) => {
  return [
    {
      imagen: imagen1,
      titulo:
        t("E-learning.Course-information.title") +
        t(
          "E-learning.Course-information.administration-and-management.title"
        ).toUpperCase(),
      text1: t(
        "E-learning.Course-information.administration-and-management.text1"
      ),
      text2: t(
        "E-learning.Course-information.administration-and-management.text2"
      ),
      tabla: [
        {
          nombre: t(
            "E-learning.Course-information.administration-and-management.table.name"
          ),
          horas: 75 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.administration-and-management.table.name2"
          ),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t(
        "E-learning.Course-information.administration-and-management.title"
      ),
      masCursos: [2, 8, 9, 5],
    },
    {
      imagen: imagen2,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.customer-support.title").toUpperCase(),
      text1: t("E-learning.Course-information.customer-support.text1"),
      text2: t("E-learning.Course-information.customer-support.text2"),
      tabla: [
        {
          nombre: t(
            "E-learning.Course-information.customer-support.table.name"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.customer-support.table.name2"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.customer-support.table.name3"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.customer-support.table.name4"
          ),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.customer-support.table.name5"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.customer-support.title"),
      masCursos: [3, 9, 10, 6],
    },
    {
      imagen: imagen3,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.trade.title").toUpperCase(),
      text1: t("E-learning.Course-information.trade.text1"),
      text2: t("E-learning.Course-information.trade.text2"),
      tabla: [
        {
          nombre: t("E-learning.Course-information.trade.table.name"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name2"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name3"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name4"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name5"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name6"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name7"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name8"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name9"),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name10"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.trade.table.name11"),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.trade.title"),
      masCursos: [4, 10, 11, 7],
    },
    {
      imagen: imagen4,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.trade-and-sales.title").toUpperCase(),
      text1: t("E-learning.Course-information.trade-and-sales.text1"),
      text2: t("E-learning.Course-information.trade-and-sales.text2"),
      tabla: [
        {
          nombre: t("E-learning.Course-information.trade-and-sales.table.name"),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name2"
          ),
          horas: 12 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name3"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name4"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name5"
          ),
          horas: 15 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name6"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name7"
          ),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name8"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name9"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name10"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.trade-and-sales.table.name11"
          ),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.trade-and-sales.title"),
      masCursos: [5, 11, 1, 8],
    },
    {
      imagen: imagen5,
      titulo:
        t("E-learning.Course-information.title") +
        t(
          "E-learning.Course-information.business-strategy-and-management.title"
        ).toUpperCase(),
      text1: t(
        "E-learning.Course-information.business-strategy-and-management.text1"
      ),
      text2: t(
        "E-learning.Course-information.business-strategy-and-management.text2"
      ),
      tabla: [
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name2"
          ),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name3"
          ),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name4"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name5"
          ),
          horas: 28 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name6"
          ),
          horas: 5 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.business-strategy-and-management.table.name7"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t(
        "E-learning.Course-information.business-strategy-and-management.title"
      ),
      masCursos: [6, 1, 2, 9],
    },
    {
      imagen: imagen6,
      titulo:
        t("E-learning.Course-information.title") +
        t(
          "E-learning.Course-information.marketing-and-internet.title"
        ).toUpperCase(),
      text1: t("E-learning.Course-information.marketing-and-internet.text1"),
      text2: t("E-learning.Course-information.marketing-and-internet.text2"),
      tabla: [
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name"
          ),
          horas: 120 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name2"
          ),
          horas: 8 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name3"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name4"
          ),
          horas: 20 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name5"
          ),
          horas: 20 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name6"
          ),
          horas: 15 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name7"
          ),
          horas: 20 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name8"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name9"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name10"
          ),
          horas: 15 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name11"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name12"
          ),
          horas: 20 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name13"
          ),
          horas: 6 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name14"
          ),
          horas: 15 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.marketing-and-internet.table.name15"
          ),
          horas: 30 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: "Marketing e Internet",
      masCursos: [7, 2, 3, 10],
    },
    {
      imagen: imagen7,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.human-resources.title").toUpperCase(),
      text1: t("E-learning.Course-information.human-resources.text1"),
      text2: t("E-learning.Course-information.human-resources.text2"),
      tabla: [
        {
          nombre: t("E-learning.Course-information.human-resources.table.name"),
          horas: 100 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name2"
          ),
          horas: 45 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name3"
          ),
          horas: 21 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name4"
          ),
          horas: 4 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name5"
          ),
          horas: 4 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name6"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.human-resources.table.name7"
          ),
          horas: 15 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.human-resources.title"),
      masCursos: [8, 3, 4, 11],
    },
    {
      imagen: imagen8,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.languajes.title").toUpperCase(),
      text1: t("E-learning.Course-information.languajes.text1"),
      text2: t("E-learning.Course-information.languajes.text2"),
      tabla: [
        {
          nombre: t("E-learning.Course-information.languajes.table.name"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name2"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name3"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name4"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name5"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name6"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name7"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name8"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name9"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name10"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name11"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name12"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name13"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.languajes.table.name14"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.languajes.title"),
      masCursos: [9, 4, 5, 1],
    },
    {
      imagen: imagen9,
      titulo:
        t("E-learning.Course-information.title") +
        t("E-learning.Course-information.design.title").toUpperCase(),
      text1: t("E-learning.Course-information.design.text1"),
      text2: t("E-learning.Course-information.design.text2"),
      tabla: [
        {
          nombre: t("E-learning.Course-information.design.table.name"),
          horas: 30 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.design.table.name2"),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.design.table.name3"),
          horas: 60 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.design.table.name4"),
          horas: 80 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.design.table.name5"),
          horas: 80 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t("E-learning.Course-information.design.table.name6"),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.design.title"),
      masCursos: [10, 5, 6, 2],
    },
    {
      imagen: imagen10,
      titulo:
        t("E-learning.Course-information.title") +
        t(
          "E-learning.Course-information.management-skills.title"
        ).toUpperCase(),
      text1: t("E-learning.Course-information.management-skills.text1"),
      text2: t("E-learning.Course-information.management-skills.text2"),
      tabla: [],
      curso: t("E-learning.Course-information.management-skills.title"),
      masCursos: [11, 6, 7, 3],
    },
    {
      imagen: imagen11,
      titulo:
        t("E-learning.Course-information.title") +
        t(
          "E-learning.Course-information.programming-and-databases.title"
        ).toUpperCase(),
      text1: t("E-learning.Course-information.programming-and-databases.text1"),
      text2: t("E-learning.Course-information.programming-and-databases.text2"),
      tabla: [
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name"
          ),
          horas: 30 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name2"
          ),
          horas: 10 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name3"
          ),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name4"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name5"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name6"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name7"
          ),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name8"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name9"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name10"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name11"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name12"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name13"
          ),
          horas: 25 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name14"
          ),
          horas: 50 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
        {
          nombre: t(
            "E-learning.Course-information.programming-and-databases.table.name15"
          ),
          horas: 40 + " " + t("E-learning.Elearning.hours").toUpperCase(),
        },
      ],
      curso: t("E-learning.Course-information.programming-and-databases.title"),
      masCursos: [1, 7, 8, 4],
    },
  ];
};

const infoCurso = (id, t) => {
  return array(t)[id - 1];
};

export default infoCurso;
