import React from 'react'
import style from './Segundaparte.module.css'

import imagen1 from '../../../../utils/images/destination.png'
import imagen2 from '../../../../utils/images/eye-tracking.png'
import imagen3 from '../../../../utils/images/time-passing.png'
import imagen4 from '../../../../utils/images/notification.png'
import imagen5 from '../../../../utils/images/line-graphic.png'
import imagen6 from '../../../../utils/images/phone.png'
import imagen7 from '../../../../utils/images/ear.png'
import imagen8 from '../../../../utils/images/radar.png'

const SegundaParte = ({ t }) => {

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.Geontracker.SecondPart.title')}</p>
      <div className={style.cardContainer}>
        <div className={style.cardA}>
          <img className={style.imagen} src={imagen1} alt="" />
          <div className={style.divText}>
            <p className={style.textA}>{t('Products.Geontracker.SecondPart.text')}</p>
            <p className={style.textB}>{t('Products.Geontracker.SecondPart.text2')}</p>
          </div>
        </div>
        <div className={style.cardA}>
          <img className={style.imagen} src={imagen2} alt="" />
          <div className={style.divText}>
            <p className={style.textA}>{t('Products.Geontracker.SecondPart.text3')}</p>
            <p className={style.textB}>{t('Products.Geontracker.SecondPart.text4')}</p>
          </div>
        </div>
      </div>
      <div className={style.cardContainerA}>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen3} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text5')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text6')}</p>
        </div>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen4} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text7')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text8')}</p>
        </div>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen5} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text9')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text10')}</p>
        </div>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen6} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text11')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text12')}</p>
        </div>
      </div>
      <div className={style.cardContainer}>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen7} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text13')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text14')}</p>
        </div>
        <div className={style.cardB}>
          <img className={style.imagen} src={imagen8} alt="" />
          <p className={style.text1}>{t('Products.Geontracker.SecondPart.text15')}</p>
          <p className={style.text2}>{t('Products.Geontracker.SecondPart.text16')}</p>
        </div>
      </div>
    </div>
  )
}

export default SegundaParte