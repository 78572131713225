import React from 'react'
import style from './Clientes.module.css'
import Presentacion from './clientes/Presentacion'
import Empresas from './clientes/Empresas'
import ServiciosComp from './clientes/ServiciosComp'

const Clientes = () => {
  return (
    <div className={style.clientes}>
      <Presentacion/>
      <Empresas/>
      <ServiciosComp/>
    </div>
  )
}

export default Clientes