import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, HashRouter } from "react-router-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import es from "./components/traduccion/es.json";
import en from "./components/traduccion/en.json";
import pt from "./components/traduccion/pt.json";

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: (cb) => {
    const storedLang = localStorage.getItem("i18nextLng");
    const language = storedLang || navigator.language || navigator.userLanguage;
    const selectedLanguage = language.split("-")[0];
    cb(selectedLanguage);
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem("i18nextLng", lng);
  },
};

i18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      pt: {
        translation: pt,
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
