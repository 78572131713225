import React from 'react'
import style from './MasProductos.module.css'

import imagen4 from '../../../../utils/images/box-4.png'
import imagen2 from '../../../../utils/images/box-2.png'
import CardProductos from '../../../generales/CardProductos'

const MasProductos = ({ t }) => {

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.BusinessSolution.More-products.title')}</p>
      <div className={style.productos}>
        <CardProductos
          imagen={imagen4}
          link={"/productos/sistema-de-intervencion-y-control"}
          title={"HomePage.Products.supervision-and-control-system"}
          text={"HomePage.Products.description-of-supervision-and-control-system"}
        />
        <CardProductos
          imagen={imagen2}
          link={"/productos/evo-scada-server"}
          title={"HomePage.Products.evo-scada-server"}
          text={"HomePage.Products.description-of-evo-scada-server"}
        />
      </div>
    </div>
  )
}

export default MasProductos