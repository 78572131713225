import React from 'react'
import style from './PrimeraParte.module.css'

const PrimeraParte = ({ t }) => {
  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.BusinessSolution.FirstPart.title')}</p>
      <div className={style.divContainer}>
        <div className={style.divIzq}>
          <p className={style.text}>{t('Products.BusinessSolution.FirstPart.text')}</p>
          <p className={style.text}>{t('Products.BusinessSolution.FirstPart.text2')}</p>
          <p className={style.text}>{t('Products.BusinessSolution.FirstPart.text3')}</p>
        </div>
        <div className={style.divDer}>
          <p className={style.text}>{t('Products.BusinessSolution.FirstPart.text4')}</p>
          <p className={style.text}>{t('Products.BusinessSolution.FirstPart.text5')}</p>
        </div>
      </div>
    </div>
  )
}

export default PrimeraParte