import React from 'react'
import style from './Solicitar.module.css'
import { Link } from 'react-router-dom';

const Solicitar = ({ t }) => {
  return (
    <div className={style.servicios}>
      <div className={style.divText}>
        <p className={style.text1}>{t('Products.BusinessSolution.Request.text')} <strong style={{ fontWeight: 'bold', color: 'black' }}>EVO SCADA SERVER</strong>.</p>
        <p className={style.text2}>{t('Products.BusinessSolution.Request.text2')}</p>
      </div>
      <div className={style.divButton}>
        <Link className={style.link} to='/contacto'>
          <div className={style.contacto}>{t('Products.BusinessSolution.Request.text3')}</div>
        </Link>
      </div>
    </div>
  )
}

export default Solicitar