import React from 'react'
import style from './Contacto.module.css'
import Contactanos from './contactanos/Contactanos'
import Contactos from './contactanos/Contactos'
import Carrusel from '../carrusel/Carrusel'
import FormYMapa from './contactanos/FormYMapa'

const Contacto = () => {
  return (
    <div className={style.contacto}>
      <Contactanos/>
      <Contactos/>
      <FormYMapa/>
      <Carrusel/>
    </div>
  )
}

export default Contacto