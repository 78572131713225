import React, { useState } from 'react'
import style from './Presentacion.module.css'

import imagen from '../../../../utils/images/box-3.png'

const Presentacion = ({ t }) => {

    const [hover, setHover] = useState(false)
    const [flagScroll,setFlagScroll] = useState(false)

    if(!flagScroll){
        setFlagScroll(true)
        window.scrollTo(0,0)
    }

    return (
        <div className={style.container}>
            <div className={style.divIzq}>
                <div className={style.circulosContenedor} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={hover ? style.circulo2Hover : style.circulo2}>
                        <div className={hover ? style.circulo1Hover : style.circulo1}>
                            <div className={hover ? style.circuloImagenHover : style.circuloImagen}>
                                <img className={hover ? style.imagenHover : style.imagen} src={imagen} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.divDer}>
                <p className={style.titulo}>{t('Products.FactoryScada.Presentation.title')}</p>
                <p className={style.text1}>{t('Products.FactoryScada.Presentation.text')}</p>
                <p className={style.text2}>{t('Products.FactoryScada.Presentation.text2')}</p>
            </div>
        </div>
    )
}

export default Presentacion