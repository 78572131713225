import React from 'react'
import style from './PrimeraParte.module.css'

const PrimeraParte = ({ t }) => {
  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.Geontracker.FirstPart.title')}&nbsp;{t('Products.Geontracker.FirstPart.title2')}</p>
      <div className={style.divContainer}>
        <div className={style.divIzq}>
          <p className={style.text}>{t('Products.Geontracker.FirstPart.text')}</p>
          <p className={style.text}>{t('Products.Geontracker.FirstPart.text2')}</p>
          <p className={style.textA}>{t('Products.Geontracker.FirstPart.text3')}</p>
        </div>
        <div className={style.divDer}>
          <p className={style.title}>{t('Products.Geontracker.FirstPart.title3')}</p>
          <div className={style.separador}></div>
          <ul className={style.ul}>
            <li className={style.li}>{t('Products.Geontracker.FirstPart.li')}</li>
            <li className={style.li}>{t('Products.Geontracker.FirstPart.li2')}</li>
            <li className={style.li}>{t('Products.Geontracker.FirstPart.li3')}</li>
            <li className={style.li}>{t('Products.Geontracker.FirstPart.li4')}</li>
            <li className={style.li}>{t('Products.Geontracker.FirstPart.li5')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PrimeraParte