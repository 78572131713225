import React from 'react'
import style from './Detalles.module.css'

const Detalles = ({ info, t }) => {
    return (
        <div className={style.container} style={info.tabla.length < 1 ? { display: 'none' } : {}}>
            <p className={style.detalle}>{t("E-learning.Course-information.details")}</p>
            <div className={style.separador}></div>
            <div className={style.tabla}>
                <div className={style.fila}>
                    <p className={style.nombre}>{t("E-learning.Course-information.name")}</p>
                    <p className={style.horas}>{t("E-learning.Elearning.hours").toUpperCase()}</p>
                </div>
                {
                    info.tabla.map(f => (
                        <div key={info.tabla.indexOf(f)} className={info.tabla.indexOf(f) + 1 === info.tabla.length ? info.tabla.indexOf(f) % 2 ? style.filaBEnd : style.filaAEnd : info.tabla.indexOf(f) % 2 ? style.filaB : style.filaA}>
                            <p className={style.text1}>{f.nombre}</p>
                            <p className={style.text2}>{f.horas}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Detalles