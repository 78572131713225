import React, { useState } from 'react'
import style from './NavbarResp.module.css'
import { HiOutlineMenu } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const NavbarResp = () => {
    const [t, i18n] = useTranslation();

    const [modalMenu, setModalMenu] = useState(false)
    const [modalIdioma, setModalIdioma] = useState(false)

    const changeToSpanish = () => {
        i18n.changeLanguage('es')
    };
    const changeToEnglish = () => {
        i18n.changeLanguage('en')
    };
    const changeToPortuguese = () => {
        i18n.changeLanguage('pt')
    };

    window.onclick = e => {
        if (typeof e.srcElement.className === 'string') {
            if (!(e.srcElement.className.slice(0, 10) === 'NavbarResp')) {
                setModalIdioma(false)
                setModalMenu(false)
            }
        }
    }

    return (
        <div className={style.navbarResp}>

            <div className={style.divMenu} onClick={() => setTimeout(() => {setModalMenu(!modalMenu);setModalIdioma(false)}, 1)}>
                <HiOutlineMenu className={style.icon} />
                <div className={style.triangulo} style={modalMenu ? { display: 'flex' } : { display: 'none' }}></div>
            </div>


            <div className={style.navbarDerB} style={modalMenu ? { display: 'flex' } : { display: 'none' }}>
                <Link to='/' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.home")}</p>
                </Link>
                <Link to='/nosotros' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.us")}</p>
                </Link>
                <Link to='/clientes' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.clients")}</p>
                </Link>
                <Link to='/productos' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.products")}</p>
                </Link>
                <Link to='/servicios' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.services")}</p>
                </Link>
                <Link to='/cursos' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.elearning")}</p>
                </Link>
                <Link to='/contacto' className={style.link} onClick={()=>setModalMenu(false)}>
                    <p className={style.text}>{t("Navbar.contact")}</p>
                </Link>
                <div className={style.divIdioma}>
                    <p className={style.text} onClick={() => setTimeout(() => setModalIdioma(!modalIdioma), 2)}>{t("Navbar.language")}</p>
                    <div className={modalIdioma ? style.modalIdiomaTrue : style.modalIdiomaFalse}>
                        <p className={style.text} onClick={()=>{changeToSpanish();setModalMenu(false)}}>Español</p>
                        <p className={style.text} onClick={()=>{changeToEnglish();setModalMenu(false)}}>English</p>
                        <p className={style.text} style={{ paddingBottom: '1vh' }} onClick={()=>{changeToPortuguese();setModalMenu(false)}}>Português</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default NavbarResp