import React from 'react'
import style from './Politicas.module.css'
import { useTranslation } from 'react-i18next'
import ServiciosComp from '../inicio/servicios/Servicios';

const Politicas = () => {

  const [t] = useTranslation();
  return (
    <>
      <div className={style.container}>
        <p className={style.textTitulo}>{t("Politics.title")}</p>
      </div>
      <div className={style.textContainer}>
        <p className={style.text}>{t("Politics.text")}</p>
      </div>
      <ServiciosComp />
    </>
  )
}

export default Politicas