import React, { useState } from 'react'
import style from './Carrusel.module.css'

import imagen1 from '../../../utils/images/logos/1-Jumbo-400x400px.png'
import imagen2 from '../../../utils/images/logos/3-Spid-400x400px.png'
import imagen3 from '../../../utils/images/logos/4-Vea-400x400px.png'
import imagen4 from '../../../utils/images/logos/5-Disco-400x400px.png'
import imagen5 from '../../../utils/images/logos/8-Gbarbosa-400x400px.png'
import imagen6 from '../../../utils/images/logos/9-Mercantil-400x400px.png'
import imagen7 from '../../../utils/images/logos/10-Bretas-400x400px.png'
import imagen8 from '../../../utils/images/logos/10-Perini-400x400px.png'
import imagen9 from '../../../utils/images/logos/11-Prezunic-400x400px.png'
import imagen10 from '../../../utils/images/logos/12-Easy-400x400px.png'
import imagen11 from '../../../utils/images/logos/15-Blaisten-400x400px.png'
import imagen12 from '../../../utils/images/logos/18-ShoppingC-400x400px.png'
import imagen13 from '../../../utils/images/logos/bw-group.jpg'
import imagen14 from '../../../utils/images/logos/ConectarIgualdad.png'
import imagen15 from '../../../utils/images/logos/dynamic.png'
import imagen16 from '../../../utils/images/logos/gottert.png'
import imagen17 from '../../../utils/images/logos/Inmark.jpg'
import imagen18 from '../../../utils/images/logos/Inxpirius.png'
import imagen19 from '../../../utils/images/logos/iupix.png'
import imagen20 from '../../../utils/images/logos/LogoConcreta.png'
import imagen21 from '../../../utils/images/logos/LogoGrafmaq.png'
import imagen22 from '../../../utils/images/logos/LogoPiana.png'
import imagen23 from '../../../utils/images/logos/Procrear_Bicentenario_Argentina_84d7e_450x450.png'
import imagen24 from '../../../utils/images/logos/progresar.png'
import imagen25 from '../../../utils/images/logos/Second Skin.png'
import imagen26 from '../../../utils/images/logos/zaxera.png'
import imagen27 from '../../../utils/images/logos/zetti.png'

const Carrusel = ({ t }) => {
  const [carrusel, setCarrusel] = useState([imagen1, imagen2, imagen3, imagen4, imagen5])

  let array = [imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7, imagen8, imagen9, imagen10, imagen11, imagen12, imagen13, imagen14, imagen15, imagen16, imagen17, imagen18, imagen19, imagen20, imagen21, imagen22, imagen23, imagen24, imagen25, imagen26, imagen27]
  let paginas = []

  const paginado = () => {

    let cantItems = window.innerWidth < 500 ? 3 : window.innerWidth < 1200 ? 4 : 5
    let cantPages = Math.ceil(array.length / cantItems)
    let inicio = 0

    for (let i = 0; i < cantPages; i++) {
      let cadaPag = array.slice(inicio, inicio + cantItems)
      inicio = inicio + cantItems
      paginas.push(cadaPag)
    }
  }

  paginado()

  return (
    <div className={style.clientes}>
      <p className={style.nuestroC}>{t('Products.BusinessSolution.Carousel.text')}</p>
      <div className={style.carrusel}>
        <div className={style.divPaginas} id='paginas' >
          {
            paginas.map(p => (
              <div key={paginas.indexOf(p)} className={style.pagina}>
                {
                  p.map(i => (
                    <img key={p.indexOf(i)} src={i} className={style.imagen} alt="" />
                  ))
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Carrusel