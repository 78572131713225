import React, { useState } from 'react'
import style from './Contactos.module.css'
import { useTranslation } from 'react-i18next'
import { IoIosCall } from 'react-icons/io'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'

const Contactos = () => {
    const [t] = useTranslation();
    const [call, setCall] = useState(false)
    const [mail, setMail] = useState(false)
    const [map, setMap] = useState(false)

    return (
        <div className={style.contactos}>
            <a href={window.location.origin === "https://www.ingertec.com.br" ? "tel:+541132214318" : "tel:+541132214318"} style={{ display: 'none' }} id='telefono'></a>
            <a href={window.location.origin === "https://www.ingertec.com.br" ? "mailto:info@ingertec.com.br" : "mailto:info@ingertec.com.ar"} style={{ display: 'none' }} id='email'></a>
            <div className={style.card} onMouseEnter={() => setCall(true)} onMouseLeave={() => setCall(false)}>
                <div className={style.divTitulo}>
                    <div className={call ? style.divIconHover : style.divIcon}>
                        <IoIosCall className={style.icon} />
                    </div>
                    <p className={style.titulo}>{t("Contact.Contacts.phone")}</p>
                </div>
                <p className={style.text1}>{t("Contact.Contacts.paragraph-phone")}</p>
                <p className={call ? style.text2Hover : style.text2} onClick={() => document.getElementById('telefono').click()}>{window.location.origin === "https://www.ingertec.com.br" ? "(+54 11) 3221-4318" : "(+54 11) 3221-4318"}</p>
            </div>
            <div className={style.card} onMouseEnter={() => setMail(true)} onMouseLeave={() => setMail(false)}>
                <div className={style.divTitulo}>
                    <div className={mail ? style.divIconHover : style.divIcon}>
                        <MdOutlineEmail className={style.icon} />
                    </div>
                    <p className={style.titulo}>EMAIL</p>
                </div>
                <p className={style.text1}>{t("Contact.Contacts.paragraph-email")}</p>
                <p className={mail ? style.text2Hover : style.text2} onClick={() => document.getElementById('email').click()}>
                    {
                        window.location.origin === "https://www.ingertec.com.br" ?
                        "info@ingertec.com.br" :
                        "info@ingertec.com.ar"
                    }
                </p>
            </div>
            <div className={style.card} onMouseEnter={() => setMap(true)} onMouseLeave={() => setMap(false)}>
                <div className={style.divTitulo}>
                    <div className={map ? style.divIconHover : style.divIcon}>
                        <FaMapMarkerAlt className={style.icon} />
                    </div>
                    <p className={style.titulo}>{t("Contact.Contacts.visit-us")}</p>
                </div>
                <p className={style.text1}>{t("Contact.Contacts.monday-to-friday")}: 9:00 AM - 6:00 PM</p>
                <p className={map ? style.text2Map : style.text2} style={window.location.origin === "https://www.ingertec.com.br" ? {fontSize: '2.8vh'} : {}}>{window.location.origin === "https://www.ingertec.com.br" ? "Rua Eduardo Luiz Gomes, N° 13, Sala 102, Centro, Niterói" : "Av. Jujuy 1963, Piso 9 Dpto A, C.A.B.A."}</p>
            </div>
        </div>
    )
}

export default Contactos