import React from 'react'
import style from './Cursos.module.css'
import Presentacion from './comps/Presentacion'
import Elearning from './comps/Elearning'
import Servicios from './comps/Servicios'

const Cursos = () => {
  return (
    <div className={style.cursos}>
      <Presentacion/>
      <Elearning/>
      <Servicios/>
    </div>
  )
}

export default Cursos