import React from 'react'
import style from './MasProductos.module.css'
import CardProductos from '../../../generales/CardProductos'
import imagen3 from '../../../../utils/images/box-3.png'
import imagen6 from '../../../../utils/images/box-6.png'

const MasProductos = ({ t }) => {

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.InteractiveVoiceResponse.More-products.title')}</p>
      <div className={style.productos}>
        <CardProductos
          imagen={imagen3}
          link={"/productos/factory-scada"}
          title={"HomePage.Products.factory-scada"}
          text={"HomePage.Products.description-of-factory-scada"}
        />
        <CardProductos
            imagen={imagen6}
            link={"/productos/geontracker"}
            title={"HomePage.Products.geontracker"}
            text={"HomePage.Products.description-of-geontracker"}
        />
      </div>
    </div>
  )
}

export default MasProductos