import React from 'react'
import style from './Servicios.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';

const Servicios = () => {

  const [t] = useTranslation();

  return (
    <div className={style.servicios}>
      <div className={style.divText}>
        <p className={style.text1}>{t("HomePage.Services.paragraph-one")}</p>
        <p className={style.text2}>{t("HomePage.Services.paragraph-two")}</p>
      </div>
      <div className={style.divButton}>
        <Link className={style.link} to='/servicios'>
          <div className={style.verServicios}>{t("HomePage.Services.see-services")}</div>
        </Link>
        <Link className={style.link} to='/contacto'>
          <div className={style.contactanos}>{t("HomePage.Services.contact-us")}</div>
        </Link>
      </div>
    </div>
  )
}

export default Servicios