import React from 'react'
import style from './Segundaparte.module.css'
import SegundaParteInfoCards from './SegundaParteInfoCards'

const SegundaParte = ({ t }) => {

  const infoCards = SegundaParteInfoCards(t)

  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.BusinessSolution.SecondPart.title')}</p>
      <div className={style.divCards}>
        {
          infoCards.map(c => (
            <div className={style.card} key={infoCards.indexOf(c)}>
              <img className={style.imagen} src={c.imagen} alt="" />
              <p className={style.title}>{c.titulo}</p>
              <p className={style.text}>{c.text}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SegundaParte