import React from 'react'
import style from './PrimeraParte.module.css'

const PrimeraParte = ({ t }) => {
  return (
    <div className={style.container}>
      <p className={style.titulo}>{t('Products.Smartsen.FirstPart.title')}</p>
      <div className={style.divContainer}>
        <div className={style.divIzq}>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text2')}</p>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text3')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text4')}</p>
        </div>
        <div className={style.divDer}>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.title3')}</p>
          <div className={style.separador}></div>
          <ul className={style.ul}>
            <li className={style.li}><span style={{fontWeight:'bold'}}>{t('Products.Smartsen.FirstPart.text5')}</span>: {t('Products.Smartsen.FirstPart.text6')}</li>
            <li className={style.li}><span style={{fontWeight:'bold'}}>{t('Products.Smartsen.FirstPart.text7')}</span>: {t('Products.Smartsen.FirstPart.text8')}</li>
            <li className={style.li}><span style={{fontWeight:'bold'}}>{t('Products.Smartsen.FirstPart.text9')}</span>: {t('Products.Smartsen.FirstPart.text10')}</li>
            <li className={style.li}><span style={{fontWeight:'bold'}}>{t('Products.Smartsen.FirstPart.text11')}</span>: {t('Products.Smartsen.FirstPart.text12')}</li>
            <li className={style.li}><span style={{fontWeight:'bold'}}>{t('Products.Smartsen.FirstPart.text13')}</span>: {t('Products.Smartsen.FirstPart.text14')}</li>
          </ul>
          {/* <p className={style.title}>{t('Products.Smartsen.FirstPart.text5')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text6')}</p>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text7')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text8')}</p>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text9')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text10')}</p>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text11')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text12')}</p>
          <p className={style.title}>{t('Products.Smartsen.FirstPart.text13')}</p>
          <p className={style.text}>{t('Products.Smartsen.FirstPart.text14')}</p> */}
        </div>
      </div>
    </div>
  )
}

export default PrimeraParte