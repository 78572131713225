import { Route, Routes } from 'react-router-dom';
import './App.css';
import PageInicio from './components/inicio/PageInicio';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Nosotros from './components/nosotros/Nosotros';
import Clientes from './components/clientes/Clientes';
import Productos from './components/productos/Productos';
import Servicios from './components/servicios/Servicios';
import Cursos from './components/cursos/Cursos';
import Contacto from './components/contacto/Contacto';
import Curso from './components/paginaCurso/Curso';
import Geontracker from './components/paginaProducto/geontracker/Geontracker';
import Smartsen from './components/paginaProducto/smartsen/Smartsen';
/* import WhatsappBot from './components/paginaProducto/whatsappBot/WhatsappBot'; */
import EvoScadaServer from './components/paginaProducto/evoScadaServer/EvoScadaServer';
import FactoryScada from './components/paginaProducto/factoryScada/FactoryScada';
import SistemaDeIntervencionYControl from './components/paginaProducto/sistemaDeIntervencionYControl/SistemaDeIntervencionYControl';
import InteractiveVoiceResponse from './components/paginaProducto/interactiveVoiceResponse/InteractiveVoiceResponse';
import BusinessSolution from './components/paginaProducto/businessSolution/BusinessSolution';
import Politicas from './components/politicas/Politicas';

function App() {

  return (
    <div className="app">
      {
        <>
          <Navbar />
          <Routes>
            <Route path='/' Component={PageInicio} />
            <Route path='/nosotros' Component={Nosotros} />
            <Route path='/clientes' Component={Clientes} />
            <Route path='/productos' Component={Productos} />
            <Route path='/servicios' Component={Servicios} />
            <Route path='/cursos' Component={Cursos} />
            <Route path='/contacto' Component={Contacto} />
            <Route path='/cursos/:id' Component={Curso} />
            <Route path='/politicas' Component={Politicas} />
            <Route path='/productos/business-solution' Component={BusinessSolution} />
            <Route path='/productos/interactive-voice-response' Component={InteractiveVoiceResponse} />
            <Route path='/productos/sistema-de-intervencion-y-control' Component={SistemaDeIntervencionYControl} />
            <Route path='/productos/factory-scada' Component={FactoryScada} />
            <Route path='/productos/evo-scada-server' Component={EvoScadaServer} />
            <Route path='/productos/geontracker' Component={Geontracker} />
            <Route path='/productos/smartsen' Component={Smartsen} />
            {/* <Route path='/productos/whatsapp-bot' Component={WhatsappBot} /> */}
          </Routes>
          <Footer />
        </>
      }
    </div>
  );
}

export default App;
