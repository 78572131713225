import imagen1 from "../../utils/images/AdministracionGestion.jpg";
import imagen2 from "../../utils/images/AtencionCliente.jpg";
import imagen3 from "../../utils/images/CursoComercio.jpg";
import imagen4 from "../../utils/images/ComercialVentas.jpg";
import imagen5 from "../../utils/images/EstrategiaEmpresarial.jpg";
import imagen6 from "../../utils/images/MarketingInternet.jpg";
import imagen7 from "../../utils/images/RecursosHumanos.jpg";
import imagen8 from "../../utils/images/CursoIdiomas.jpg";
import imagen9 from "../../utils/images/CursoDisenio.jpg";
import imagen10 from "../../utils/images/HabilidadesDirectivas.jpg";
import imagen11 from "../../utils/images/Programacion.jpg";

const array = (t) => {
  return [
    {
      imagen: imagen1,
      nombre: t(
        "E-learning.Course-information.administration-and-management.title"
      ),
      horas: 135,
    },
    {
      imagen: imagen2,
      nombre: t("E-learning.Course-information.customer-support.title"),
      horas: 33,
    },
    {
      imagen: imagen3,
      nombre: t("E-learning.Course-information.trade.title"),
      horas: 145,
    },
    {
      imagen: imagen4,
      nombre: t("E-learning.Course-information.trade-and-sales.title"),
      horas: 138,
    },
    {
      imagen: imagen5,
      nombre: t(
        "E-learning.Course-information.business-strategy-and-management.title"
      ),
      horas: 189,
    },
    {
      imagen: imagen6,
      nombre: t("E-learning.Course-information.marketing-and-internet.title"),
      horas: 369,
    },
    {
      imagen: imagen7,
      nombre: t("E-learning.Course-information.human-resources.title"),
      horas: 199,
    },
    {
      imagen: imagen8,
      nombre: t("E-learning.Course-information.languajes.title"),
      horas: 840,
    },
    {
      imagen: imagen9,
      nombre: t("E-learning.Course-information.design.title"),
      horas: 325,
    },
    {
      imagen: imagen10,
      nombre: t("E-learning.Course-information.management-skills.title"),
      horas: 0,
    },
    {
      imagen: imagen11,
      nombre: t(
        "E-learning.Course-information.programming-and-databases.title"
      ),
      horas: 500,
    },
  ];
};

const infoCardCursos = (t) => {
  return array(t);
};

export default infoCardCursos;
