import React from 'react'
import style from './Consulta.module.css'
import { Link } from 'react-router-dom';

const Consulta = ({ info, t }) => {

  return (
    <div className={style.servicios}>
      <div className={style.divText}>
        {
          info.curso.length < 15 && window.innerWidth > 650 ?
            <>
              <p className={style.text1}>{t("E-learning.Course-information.consultation.text1")}&nbsp;<strong className={style.nombreCurso}>{info.curso}</strong>?.</p>
            </> :
            <>
              <p className={style.text1}>{t("E-learning.Course-information.consultation.text1")}</p>
              <p className={style.text1}><strong className={style.nombreCurso}>{info.curso}</strong>?.</p>
            </>
        }
        <p className={style.text2}>{t("E-learning.Course-information.consultation.text2")}</p>
      </div>
      <div className={style.divButton}>
        <Link className={style.link} to='/contacto'>
          <div className={style.verServicios}>{t("E-learning.Course-information.consultation.buttonName1")}</div>
        </Link>
        <Link className={style.link} to='/cursos'>
          <div className={style.contactanos}>{t("E-learning.Course-information.consultation.buttonName2")}</div>
        </Link>
      </div>
    </div>
  )
}

export default Consulta