import React, { useState } from 'react'
import style from './Presentacion.module.css'
import { useTranslation } from 'react-i18next'

import { FaCogs } from 'react-icons/fa'

const Presentacion = () => {
  
  const [flagScroll,setFlagScroll] = useState(false)

  if(!flagScroll){
      setFlagScroll(true)
      window.scrollTo(0,0)
  }

  const [t] = useTranslation();

  return (
    <div className={style.container}>
      <p className={style.title}>{t("E-learning.Presentation.title")}</p>
      <p className={style.text}>{t("E-learning.Presentation.text")}</p>
      <div className={style.divIcon}>
        <FaCogs className={style.icon} />
      </div>
    </div>
  )
}

export default Presentacion